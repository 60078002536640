// Generated by Framer (3fa6aa4)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Feather } from "https://framerusercontent.com/modules/f0DboytQenYh21kfme7W/zb1zVBMZJKgPMiedOi0y/Feather.js";
const FeatherFonts = getFonts(Feather);

const enabledGestures = {AVFzUmYRG: {hover: true}};

const cycleOrder = ["AVFzUmYRG"];

const serializationHash = "framer-7826B"

const variantClassNames = {AVFzUmYRG: "framer-v-1gm630"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, iconName, id, link, width, ...props}) => { return {...props, ajcQqYHqD: iconName ?? props.ajcQqYHqD ?? "dribbble", g81CiUUAE: link ?? props.g81CiUUAE} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;iconName?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ajcQqYHqD, g81CiUUAE, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "AVFzUmYRG", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={g81CiUUAE} openInNewTab><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1gm630", className, classNames)} framer-iccm7h`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"AVFzUmYRG"} ref={ref ?? ref1} style={{backgroundColor: "rgba(255, 255, 255, 0)", borderBottomLeftRadius: 6, borderBottomRightRadius: 6, borderTopLeftRadius: 6, borderTopRightRadius: 6, ...style}} variants={{"AVFzUmYRG-hover": {backgroundColor: "rgba(21, 19, 18, 0.08)"}}} {...addPropertyOverrides({"AVFzUmYRG-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-17kc002-container"} layoutDependency={layoutDependency} layoutId={"QjQW7V2Ml-container"}><Feather color={"var(--token-733bbd01-4431-42a0-b8b0-48fb9de5f3db, rgb(244, 108, 56))"} height={"100%"} iconSearch={ajcQqYHqD} iconSelection={"home"} id={"QjQW7V2Ml"} layoutId={"QjQW7V2Ml"} mirrored={false} selectByList={false} style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></ComponentViewportProvider></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-7826B.framer-iccm7h, .framer-7826B .framer-iccm7h { display: block; }", ".framer-7826B.framer-1gm630 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: auto; justify-content: center; overflow: hidden; padding: 6px; position: relative; text-decoration: none; width: auto; will-change: var(--framer-will-change-override, transform); }", ".framer-7826B .framer-17kc002-container { flex: none; height: 20px; position: relative; width: 20px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-7826B.framer-1gm630 { gap: 0px; } .framer-7826B.framer-1gm630 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-7826B.framer-1gm630 > :first-child { margin-left: 0px; } .framer-7826B.framer-1gm630 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"sOPFBH_So":{"layout":["auto","auto"]}}}
 * @framerVariables {"ajcQqYHqD":"iconName","g81CiUUAE":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerooIfBq9Ay: React.ComponentType<Props> = withCSS(Component, css, "framer-7826B") as typeof Component;
export default FramerooIfBq9Ay;

FramerooIfBq9Ay.displayName = "Social Icon";

FramerooIfBq9Ay.defaultProps = {height: 32, width: 32};

addPropertyControls(FramerooIfBq9Ay, {ajcQqYHqD: {defaultValue: "dribbble", placeholder: "Dribble, Twitter, Youtube", title: "Icon Name", type: ControlType.String}, g81CiUUAE: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerooIfBq9Ay, [{explicitInter: true, fonts: []}, ...FeatherFonts], {supportsExplicitInterCodegen: true})